<template>
  <div v-loading="dataLoading" v-loading.fullscreen="actionLoading">
    <el-table :data="product.aliases">
      <el-table-column type="index" label="#" />
      <el-table-column prop="sku" :label="$t('commons.sku')" />
      <el-table-column prop="name" :label="$t('commons.name')" />
      <el-table-column>
        <template #default="{ row }">
          <el-tag>{{ row.alias ? $t('commons.alias') : $t('commons.primary') }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column :align="$direction === 'ltr' ? 'left' : 'right'">
        <template #default="{ row }">
          <el-dropdown trigger="click" @command="handleDropdownCommand">
            <span class="el-dropdown-link">
              <i class="co-kebab" />
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item :disabled="!row.alias" :command="{ action: 'unlink', row }">{{
                  $t('product.unlink')
                }}</el-dropdown-item>
                <el-dropdown-item :disabled="!row.alias" :command="{ action: 'primary', row }">{{
                  $t('product.setAsPrimary')
                }}</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <div class="mt-4 d-flex flex-row-reverse">
      <el-button type="primary" @click="handleAddLinkedProductButton">+</el-button>
    </div>
    <el-dialog
      :visible="isAddProductModalOpen"
      append-to-body
      :title="`${$t('product.linkProductTo')} ${currentPrimaryName}`"
      width="20%"
      center
      @close="isAddProductModalOpen = false"
    >
      <el-form ref="addAliasProductForm" :model="addAliasProductForm" :show-message="false">
        <div class="mb-5">
          <el-form-item prop="selectedAlias" required>
            <el-select
              v-model="addAliasProductForm.selectedAlias"
              class="w-100"
              filterable
              :placeholder="$t('commons.select')"
            >
              <el-option
                v-for="businessProduct in businessProductsToAdd"
                :key="businessProduct.id"
                :label="`${businessProduct.sku ? `${businessProduct.sku} -` : ''} ${businessProduct.name}`"
                :value="businessProduct.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="d-flex justify-content-between">
          <el-button @click="handleAddProductCancel">{{ $t('commons.cancel') }}</el-button>
          <el-button class="add-linked-product-add-button" type="primary" @click="handleAddProductConfirm">{{
            $t('commons.add')
          }}</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { ref, computed, getCurrentInstance } from 'vue';
import { useProduct } from '@/modules/products/compositions/product';
import { useBusinessProducts } from '@/modules/products/compositions/useBusinessProducts';
import {
  SET_PRODUCT_ALIAS_MUTATION,
  REMOVE_PRODUCT_ALIAS_MUTATION,
  SET_PRODUCT_PRIMARY_ALIAS_MUTATION,
} from '@/modules/products/utils/products-queries';
import { useMutation } from '@vue/apollo-composable';

export default {
  name: 'LinkedProducts',
  props: {
    currentProduct: { type: Object, required: true },
  },
  setup(props) {
    const root = getCurrentInstance().proxy;
    const productId = computed(() => props.currentProduct.id);
    const businessId = computed(() => props.currentProduct.businessId);
    const { product, loading: productLoading, refetch: refetchProduct } = useProduct(productId);
    const {
      products: businessProducts,
      refetch: refetchBusinessProducts,
      loading: businessProductsLoading,
    } = useBusinessProducts(businessId);
    const {
      mutate: setAlias,
      loading: setAliasLoading,
      onDone: setAliasOnDone,
      onError: setAliasOnError,
    } = useMutation(SET_PRODUCT_ALIAS_MUTATION);
    const {
      mutate: removeAlias,
      loading: removeAliasLoading,
      onDone: removeAliasOnDone,
      onError: removeAliasOnError,
    } = useMutation(REMOVE_PRODUCT_ALIAS_MUTATION);
    const {
      mutate: setProductPrimaryAlias,
      loading: setProductPrimaryAliasLoading,
      onDone: setProductPrimaryAliasOnDone,
      onError: setProductPrimaryAliasOnError,
    } = useMutation(SET_PRODUCT_PRIMARY_ALIAS_MUTATION);
    setAliasOnDone(() => root.$message.success(root.$i18n.t('product.notification.productLinkSuccess')));
    setAliasOnError(() => root.$message.error(root.$i18n.t('product.notification.productLinkError')));

    removeAliasOnDone(() => root.$message.success(root.$i18n.t('product.notification.productUnlinkSuccess')));
    removeAliasOnError(() => root.$message.error(root.$i18n.t('product.notification.productUnlinkError')));

    setProductPrimaryAliasOnDone(() =>
      root.$message.success(root.$i18n.t('product.notification.productSetToPrimarySuccess'))
    );
    setProductPrimaryAliasOnError(() =>
      root.$message.error(root.$i18n.t('product.notification.productSetToPrimaryError'))
    );

    const currentProductPrimary = computed(() =>
      businessProducts.value.find((businessProduct) => businessProduct.id === props.currentProduct.alias)
    );

    return {
      product,
      refetchProduct,
      setAlias,
      removeAlias,
      setProductPrimaryAlias,
      refetchBusinessProducts,
      businessProducts,
      businessProductsToAdd: computed(() =>
        businessProducts.value.filter(
          (businessProduct) =>
            businessProduct.id !== product.value.id && businessProduct.aliases && !businessProduct.aliases.length
        )
      ),
      dataLoading: computed(() => productLoading.value && businessProductsLoading.value),
      actionLoading: computed(
        () => setAliasLoading.value || removeAliasLoading.value || setProductPrimaryAliasLoading.value
      ),
      isAddProductModalOpen: ref(false),
      isPrimaryProduct: ref(false),
      currentPrimaryName: computed(() =>
        props.currentProduct.alias
          ? currentProductPrimary.value && currentProductPrimary.value.name
          : props.currentProduct.name
      ),
    };
  },
  data() {
    return {
      addAliasProductForm: {
        selectedAlias: null,
      },
    };
  },
  methods: {
    handleAddLinkedProductButton() {
      this.addAliasProductForm.selectedAlias = null;
      this.isAddProductModalOpen = true;
    },
    handleDropdownCommand(command) {
      const { action, row: product } = command;
      switch (action) {
        case 'unlink':
          this.handleUnlink(product);
          break;
        case 'primary':
          this.handleSetPrimary(product);
          break;
      }
    },
    handleUnlink(product) {
      this.$confirm(this.$i18n.t('commons.areYouSure'), {
        type: 'warning',
      })
        .then(() => {
          this.removeAlias({ productId: product.id });
          this.refetchProduct();
          this.refetchBusinessProducts();
        })
        .catch(() => {});
    },
    handleSetPrimary(product) {
      this.$confirm(this.$i18n.t('commons.areYouSure'), {
        type: 'warning',
      })
        .then(() => {
          this.setProductPrimaryAlias({ productId: product.id });
          this.refetchBusinessProducts();
          this.refetchProduct();
        })
        .catch(() => {});
    },
    handleAddProductCancel() {
      this.isAddProductModalOpen = false;
      this.addAliasProductForm.selectedAlias = null;
    },
    handleAddProductConfirm() {
      this.$refs.addAliasProductForm.validate((valid) => {
        if (valid) {
          this.isAddProductModalOpen = false;
          const primaryProduct = this.businessProducts.find((product) => product?.aliases?.length);
          const productToLinkTo = primaryProduct ? primaryProduct.id : this.product.id;
          this.setAlias({ productId: this.addAliasProductForm.selectedAlias, productAliasId: productToLinkTo });
          this.refetchProduct();
          this.refetchBusinessProducts();
          this.addAliasProductForm.selectedAlias = null;
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.add-linked-product-add-button {
  margin: 0;
}
</style>
