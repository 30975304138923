import { computed } from 'vue';
import { useNotification } from '@/modules/core';
import { useQuery } from '@vue/apollo-composable';
import { PRODUCTS_QUERY, PRODUCTS_QUERY_NEW } from '@/modules/products/utils/products-queries';

export function useBusinessProducts(businessId) {
  const { error } = useNotification();

  const { result, refetch, loading, onError } = useQuery(
    PRODUCTS_QUERY,
    () => ({ businessId: businessId.value }),
    () => ({ enabled: !!businessId.value })
  );

  const emptyProducts = [
    {
      id: null,
      name: null,
      sku: null,
    },
  ];

  const products = computed(() => (result.value ? result.value.productsOldNew?.nodes ?? emptyProducts : emptyProducts));

  onError((err) => {
    console.error('useBusinessProducts', err);
    error();
  });

  return {
    businessId,
    loading,
    products,
    refetch,
  };
}

export function useBusinessProductsNew(businessId) {
  const { error } = useNotification();

  const { result, refetch, loading, onError } = useQuery(
    PRODUCTS_QUERY_NEW,
    () => ({ businessId: businessId.value }),
    () => ({ enabled: !!businessId.value })
  );

  const emptyProducts = [
    {
      id: null,
      name: null,
      sku: null,
    },
  ];

  const products = computed(() => (result.value ? result.value.productsOldNew?.nodes ?? emptyProducts : emptyProducts));

  onError((err) => {
    console.error('useBusinessProducts', err);
    error();
  });

  return {
    businessId,
    loading,
    products,
    refetch,
  };
}
