<template>
  <el-form ref="form" :model="model" :rules="rules">
    <div class="row">
      <div class="col-2">
        {{ $t('commons.name') }}
      </div>
      <div class="col">
        <el-form-item prop="name">
          <el-input v-model="model.name" v-popover:similarProducts />
          <el-popover
            ref="similarProducts"
            :placement="$direction === 'rtl' ? 'left' : 'right'"
            trigger="manual"
            :value="hasSimilarProducts"
          >
            <strong>{{ $t('product.similarProducts') }}</strong>
            <table class="table table-sm">
              <thead>
                <tr>
                  <th>{{ $t('product.product') }}</th>
                  <th>{{ $t('product.score') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="{ product: item, score } in similarProducts" :key="item.id">
                  <td>{{ item.sku ? `${item.sku} - ${item.name}` : item.name }}</td>
                  <td>{{ score.toFixed(3) }}</td>
                </tr>
              </tbody>
            </table>
          </el-popover>
        </el-form-item>
      </div>
    </div>
    <div class="row">
      <div class="col-2">
        {{ $t('commons.sku') }}
      </div>
      <div class="col">
        <el-form-item prop="sku">
          <el-input v-model="model.sku" />
        </el-form-item>
      </div>
    </div>
    <div class="row">
      <div class="col-2">{{ $t('product.gtin') }}</div>
      <div class="col">
        <el-form-item prop="gtin">
          <el-input v-model="model.gtin" />
        </el-form-item>
      </div>
    </div>
    <div class="row">
      <div class="col-2">
        {{ $t('commons.category') }}
      </div>
      <div class="col">
        <el-form-item prop="categoryId">
          <el-cascader
            v-model="model.categoryId"
            :options="categoryTrees"
            filterable
            clearable
            :props="{ checkStrictly: true, emitPath: false, value: '_id', label: 'name' }"
            class="w-100"
          />
        </el-form-item>
      </div>
    </div>
    <div class="row">
      <div class="col-2">{{ $t('product.unitOfMeasure') }}</div>
      <div class="col">
        <el-form-item prop="uom">
          <el-cascader
            v-model="model.uom"
            :options="uomOptions"
            clearable
            :props="{ emitPath: false, expandTrigger: 'hover' }"
            class="w-100"
            @change="(value) => value || $delete(model, 'bom')"
          />
        </el-form-item>
      </div>
    </div>
    <el-form-item :label="$t('product.bom')">
      <el-switch v-model="bomConfig" :disabled="!model.uom" />
      <small class="text-muted">
        <template v-if="model.uom"> One {{ model.uom }} is made of </template>
        <template v-else> {{ $t('product.configureUOM') }} </template>
      </small>
      <template v-if="bomConfig">
        <div class="row">
          <div class="col">
            <el-form-item prop="bom.quantity">
              <el-input-number
                v-model="model.bom.quantity"
                placeholder="quantity"
                controls-position="right"
                class="w-100"
              />
            </el-form-item>
          </div>
          <div class="col">
            <el-form-item prop="bom.uom">
              <el-cascader
                v-model="model.bom.uom"
                :options="uomOptions"
                clearable
                :props="{ emitPath: false, expandTrigger: 'hover' }"
                placeholder="UOM"
                class="w-100"
                @change="(value) => value || $delete(model.bom, 'bom')"
              />
            </el-form-item>
          </div>
        </div>

        <el-switch v-model="bomBomConfig" :disabled="!model.bom.uom" />
        <small class="text-muted">
          <template v-if="model.bom.uom"> One {{ model.bom.uom }} is made of </template>
          <template v-else> Configure UOM to configure BOM </template>
        </small>

        <div v-if="bomBomConfig" class="row">
          <div class="col">
            <el-form-item prop="bom.bom.quantity">
              <el-input-number
                v-model="model.bom.bom.quantity"
                placeholder="quantity"
                controls-position="right"
                class="w-100"
              />
            </el-form-item>
          </div>
          <div class="col">
            <el-form-item prop="bom.bom.uom">
              <el-cascader
                v-model="model.bom.bom.uom"
                :options="uomOptions"
                clearable
                :props="{ emitPath: false, expandTrigger: 'hover' }"
                placeholder="UOM"
                class="w-100"
              />
            </el-form-item>
          </div>
        </div>
      </template>
    </el-form-item>
    <el-button type="primary" class="float-right" @click="onSubmit">{{ $t('commons.submit') }}</el-button>
    <LinkedProducts v-if="product.id" :current-product="product" />
    <div class="clearfix" />
  </el-form>
</template>

<script type="text/javascript">
import leven from 'leven';

import { ProductSchema, unitsOfMeasure } from '../models/product';
import LinkedProducts from './LinkedProducts';

const getScore = (text1, text2) => leven(text1, text2) / Math.max(text1.length, text2.length);

const UOM_OPTIONS = Object.keys(unitsOfMeasure).map((key) => ({
  value: key,
  label: key,
  children: unitsOfMeasure[key].map((unit) => ({ value: unit, label: unit })),
}));

const getEmptyModel = () => ({
  name: '',
  businessId: '',
  sku: '',
  gtin: '',
  categoryId: '',
  uom: '',
});

export default {
  components: { LinkedProducts },
  props: {
    product: { type: Object, default: () => ({}) },
    categoryTrees: { type: Array, default: () => [] },
    businessProducts: { type: Array, default: () => [] },
  },
  data() {
    return {
      model: Object.assign(getEmptyModel(), ProductSchema.clean(this.product)),
      lastPurchased: null,
      mounted: false,
    };
  },
  computed: {
    uomOptions: () => UOM_OPTIONS,
    rules() {
      return {
        name: { required: true },
        'bom.quantity': { required: true },
        'bom.uom': { required: true },
        'bom.bom.quantity': { required: true },
        'bom.bom.uom': { required: true },
      };
    },
    bomConfig: {
      get() {
        return Boolean(this.model.bom);
      },
      set(value) {
        if (value) this.$set(this.model, 'bom', { quantity: undefined, uom: '' });
        else delete this.$delete(this.model, 'bom');
      },
    },
    bomBomConfig: {
      get() {
        return Boolean(this.model.bom.bom);
      },
      set(value) {
        if (value) this.$set(this.model.bom, 'bom', { quantity: undefined, uom: '' });
        else delete this.$delete(this.model.bom, 'bom');
      },
    },
    similarProducts() {
      if (this.model.name.length < 3) return [];
      return this.businessProducts
        .map((product) => ({
          product,
          score: getScore(product.name, this.model.name),
        }))
        .sort(({ score: score1 }, { score: score2 }) => score1 - score2)
        .slice(0, 5);
    },
    hasSimilarProducts() {
      return this.mounted && !!this.similarProducts.length;
    },
  },
  mounted() {
    this.mounted = true;
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (!valid) return;
        this.$emit('submit', ProductSchema.clean(this.model));
      });
    },
  },
};
</script>
