<template>
  <el-popover
    class="icon-container rounded d-flex"
    popper-class="text-typography-primary p-2 rounded"
    :visible-arrow="false"
    placement="bottom-end"
    width="400"
    trigger="hover"
  >
    <p class="pt-2 pb-4 fw-bold">{{ $t('product.additionalNames') }}</p>
    <div class="mt-1 border rounded py-1">
      <table class="table table-sm">
        <thead>
          <tr>
            <th style="width: 30%">{{ $t('commons.sku') }}</th>
            <th style="width: 70%">{{ $t('commons.name') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(reference, index) in references" :key="index">
            <td>
              {{ reference.sku || '-' }}
            </td>
            <td>
              {{ reference.name }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <AdditionalNamesIcon slot="reference" class="rounded" :height="iconSize" :width="iconSize" />
  </el-popover>
</template>

<script>
import { AdditionalNamesIcon } from '@/assets/icons';

export default {
  components: { AdditionalNamesIcon },
  props: {
    references: { type: Array, required: true },
    iconSize: { type: Number, default: 24 },
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.icon-container:hover svg {
  background: $secondary;
}
</style>
