import { useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';
import { TERMS_QUERY, TERMS_NEW_QUERY } from '@/modules/products/utils/terms-queries';

export function useTerms(productIds) {
  const { result, loading, refetch } = useQuery(TERMS_QUERY, { productIds }, () => ({
    enabled: !!productIds.value?.length,
  }));

  const terms = computed(() => {
    return (result.value && result.value.terms) || [];
  });

  return {
    loading,
    terms,
    refetch,
  };
}

export function useTermsNew(productIds) {
  const { result, loading, refetch } = useQuery(TERMS_NEW_QUERY, { productIds }, () => ({
    enabled: !!productIds.value?.length,
  }));

  const terms = computed(() => (result.value ? result.value.termsNew?.nodes ?? [] : []));

  return {
    loading,
    terms,
    refetch,
  };
}
