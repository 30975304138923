import { useQuery, useResult } from '@vue/apollo-composable';
import { PRODUCT_ORDERS_QUERY, PRODUCT_QUERY } from '@/modules/products/utils/products-queries';
import { computed } from 'vue';
import { useNotification } from '@/modules/core';

export function useProduct(productId) {
  const { error } = useNotification();

  const { result, refetch, loading, onError } = useQuery(
    PRODUCT_QUERY,
    () => ({ productId: productId.value }),
    () => ({
      enabled: !!productId.value,
    })
  );

  const emptyProduct = {
    id: null,
    name: null,
    sku: null,
    business: {},
    alias: null,
    aliases: [],
    references: [],
    similarProducts: [],
  };

  const product = useResult(result, emptyProduct);

  onError((err) => {
    console.error('useProduct', err);
    error();
  });

  return {
    productId,
    loading,
    product,
    refetch,
  };
}

/**
 * get product orders from API
 * @param {string} productId
 * @param {Integer} [limit]
 * @returns {{orders: Readonly<Ref<Readonly<*[]>>>, loading: Ref<boolean>}}
 */
export function useProductOrders(productId, limit) {
  const variables = { productId };
  if (limit) {
    variables.limit = limit;
  }
  const { result, loading } = useQuery(PRODUCT_ORDERS_QUERY, variables);

  const orders = computed(() => (result.value && result.value.productOrders) || []);

  return {
    loading,
    orders,
  };
}
